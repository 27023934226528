import classNames from 'classnames'
import { EditorProps } from './types'
import { Content, EditorContent, useEditor } from '@tiptap/react'
import Placeholder from '@tiptap/extension-placeholder'
import { EditorProvider } from './context'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import debounce from 'lodash/debounce'
import {
  editorContainer,
  editorContainerBorderColor,
  editorContainerInner,
} from './editor.css.ts'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { vars } from '/@theme/theme.css.ts'
import { EditorToolbar } from './toolbar'
import { ForwardedRef, forwardRef, useRef } from 'react'

const Editor = forwardRef(
  (props: EditorProps, forwardedRef: ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      placeholder,
      onChange,
      onFileUpload,
      borderColor,
      value: content,
      containerStyle,
      ...editorRest
    } = props

    const hasLoadedRef = useRef(false)
    const classes = classNames('pq-editor', className, editorContainer)

    const onChangeDebounce = debounce((value) => {
      onChange(value)
    }, 10)

    const editor = useEditor({
      extensions: [
        Placeholder.configure({
          placeholder,
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Underline,
        Link.configure({
          HTMLAttributes: {
            target: '_blank',
          },
        }),
        StarterKit.configure({
          bulletList: {
            HTMLAttributes: {
              class: 'pq-list__disc',
            },
            keepMarks: true,
            keepAttributes: true,
          },
          history: false,
        }),
      ],
      onUpdate: (updateProps) => {
        // https://github.com/ueberdosis/tiptap/issues/426#issuecomment-1487323152
        const value = updateProps.editor
          .getHTML()
          .replace(/(?!^)<p><\/p>/gi, '<br>')
        onChangeDebounce(value)
      },
      editable: !editorRest?.disabled,
    })

    // set default value
    if (content && editor?.isEmpty && !hasLoadedRef.current) {
      editor?.commands?.setContent(content as Content)
      hasLoadedRef.current = true
    }

    return (
      <EditorProvider
        {...{
          editor,
          onFileUpload,
        }}
      >
        <div
          className={classes}
          style={{
            ...assignInlineVars({
              [editorContainerBorderColor]: borderColor ?? vars.color.border,
            }),
            ...containerStyle,
          }}
        >
          <div className={editorContainerInner}>
            <EditorContent editor={editor} ref={forwardedRef} />
          </div>
          <EditorToolbar {...editorRest} editor={editor} />
        </div>
      </EditorProvider>
    )
  },
)

export { Editor }
